import { Layout } from "antd";
import { ProfileQuery, ProfileQueryVariables } from "@/graphql/output/graphql";
import React, { FC, PropsWithChildren, useEffect, useState } from "react";

import Image from "next/image";
import Link from "next/link";
import { PROFILE } from "@/graphql/queries/login";
import PageFooter from "@/components/page-footer";
import PageLoader from "@/components/page-loader/PageLoader";
import { LoggedUser, UserProvider } from "@/contexts/user";
import logo from "../../../public/images/logo.png";
import styles from "./AppLayout.module.scss";
import { useAccessToken } from "@/hooks/useAccessToken";
import { useLazyQuery } from "@apollo/client";
import { useRouter } from "next/router";
import NavigationMenu from "@/components/navigation-menu/NavigationMenu";

const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [token] = useAccessToken();
  const [loading, setLoading] = useState<boolean>(true);

  const [getUser, { data }] = useLazyQuery<ProfileQuery, ProfileQueryVariables>(
    PROFILE,
    {
      fetchPolicy: "no-cache",
      nextFetchPolicy: "no-cache",
      onError: (error) => {
        if (error.message === "Unauthenticated.") {
          router.replace("/");
        }
        setLoading(false);
      },
      onCompleted: () => {
        setIsVerified(true);
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (token) {
      getUser();
    } else {
      setLoading(false);
    }
  }, [token, getUser]);

  if (loading || !isVerified) {
    return <PageLoader />;
  }

  return (
    <UserProvider user={data!.profile as LoggedUser}>
      <Layout>
        <Layout.Header className={styles.Header}>
          <Link
            href={
              data?.profile.isAdmin
                ? "/clienti"
                : `/clienti/${data?.profile.customer?.id}`
            }
            className={styles.LogoContainer}
          >
            <Image alt="Logo Arvan" src={logo} className={styles.Image} />
          </Link>
          <NavigationMenu />
        </Layout.Header>
        <Layout>
          <Layout.Content className={styles.Content}>{children}</Layout.Content>

          <Layout.Footer>
            <PageFooter />
          </Layout.Footer>
        </Layout>
      </Layout>
    </UserProvider>
  );
};

export default AppLayout;
