import { useMemo } from "react";
import userHasPermission from "@/helpers/user-has-permission";
import { useUserContext } from "@/contexts/user";

const useHasPermission = (permission: string): boolean => {
  const { user } = useUserContext();

  const hasPermission = useMemo(
    () => (user ? userHasPermission(user, permission) : false),
    [user, permission]
  );

  return hasPermission;
};

export default useHasPermission;
