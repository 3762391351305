import dayjs from "dayjs";
import "dayjs/locale/it";

export const toDate = (value: string, format: string) => {
  dayjs.locale("it");
  if (!value || !format) {
    return value;
  }

  return dayjs(value).format(format);
};
