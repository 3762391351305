import React, { FC, ReactNode, useEffect } from "react";

import { CLIENTI_PAGE } from "@/constants";
import { useAccessToken } from "@/hooks/useAccessToken";
import { useRouter } from "next/router";

const PrivateRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const [token] = useAccessToken();

  useEffect(() => {
    if (!token) {
      const url =
        router.asPath !== CLIENTI_PAGE &&
        router.asPath !== "/password-recovery" &&
        router.asPath !== "/password-reset" &&
        router.asPath !== "/invite"
          ? `/?redirect=${router.asPath}`
          : "/";

      router.push(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default PrivateRoute;
