import React, { FC, ReactNode } from "react";

import { CLIENTI_PAGE } from "@/constants";
import { useAccessToken } from "@/hooks/useAccessToken";
import { useRedirectAfterLogin } from "@/hooks/use-redirect-after-login";
import { useRouter } from "next/router";

const PublicRoute: FC<{ children: ReactNode }> = ({ children }) => {
  const router = useRouter();
  const [token] = useAccessToken();
  const [redirect] = useRedirectAfterLogin();

  if (token) {
    router.replace(redirect || CLIENTI_PAGE);
    localStorage.removeItem("redirect_after_login");
    return null;
  }

  return <>{children}</>;
};

export default PublicRoute;
