import { gql } from "@apollo/client";

export const PROFILE = gql`
  query Profile {
    profile {
      id
      name
      customer {
        id
        ragione_sociale
      }
      roles {
        id
        name
      }
      active
      email
      avatar_text
      deleted
      revoked
      last_login_at
      failed_logins
      all_permissions
      isAdmin
      isCliente
    }
  }
`;

export const LOGOUT = gql`
  query Logout {
    logout
  }
`;
