import { ApolloClient, ApolloLink, InMemoryCache, from } from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { BatchHttpLink } from "@apollo/client/link/batch-http";
import Router from "next/router";
import { createUploadLink } from "apollo-upload-client";
import { extractFiles } from "extract-files";
import { relayStylePagination } from "@apollo/client/utilities";
import { toDate } from "@/helpers/toDate";

const LINK_OPTIONS = {
  uri: `${process.env.NEXT_PUBLIC_API || "/api"}/graphql`,
};

const httpLink = ApolloLink.split(
  (operation) => extractFiles(operation).files.size > 0,
  createUploadLink(LINK_OPTIONS),
  new BatchHttpLink(LINK_OPTIONS)
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage
      .getItem(process.env.NEXT_PUBLIC_LS_TOKEN as string)
      ?.replaceAll('"', "");

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return forward(operation);
});

const logoutLink = onError((res: ErrorResponse) => {
  if (res.graphQLErrors) {
    for (const err of res.graphQLErrors) {
      if (err.extensions) {
        switch (err.extensions.status) {
          case 401:
            Router.pathname !== "/" &&
              Router.pathname !== "/logout" &&
              Router.push("/logout");
            break;

          case 404:
            Router.push("/404");
            break;

          case 403:
            Router.pathname !== "/" &&
              Router.pathname !== "/logout" &&
              Router.push("/403");
            break;

          case 500:
            Router.push("/500");
            break;
        }
      }
    }
  }
});

const client = new ApolloClient({
  connectToDevTools: process.env.NEXT_PUBLIC_ENV === "development",
  link: from([authMiddleware, logoutLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Customer: {
        fields: {
          created_at: {
            read(value: string) {
              return toDate(value, "DD MMMM YYYY HH:mm");
            },
          },
          updated_at: {
            read(value: string) {
              return toDate(value, "DD MMMM YYYY HH:mm");
            },
          },
        },
      },
      User: {
        fields: {
          last_login_at: {
            read(value: string) {
              return toDate(value, "DD MMMM YYYY HH:mm");
            },
          },
        },
      },
      Echa: {
        fields: {
          created_at: {
            read(value: string) {
              return toDate(value, "DD MMMM YYYY HH:mm");
            },
          },
        },
      },
      SchedaTecnica: {
        fields: {
          data_versione: {
            read(value: string) {
              return toDate(value, "DD MMMM YYYY");
            },
          },
        },
      },
      Query: {
        fields: {
          activity: relayStylePagination(),
        },
      },
    },
  }),
});

export default client;
