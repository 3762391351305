import React, { FC } from "react";

import Link from "next/link";
import { Menu } from "antd";
import styles from "./NavigationMenu.module.scss";
import { useRouter } from "next/dist/client/router";
import { useDeviceBreakpoints } from "@/hooks/use-device-breakpoints";
import useHasPermission from "@/hooks/use-has-permission";
import { useUserContext } from "@/contexts/user";

const NavigationMenu: FC = () => {
  const router = useRouter();

  const user = useUserContext();

  let active = router.pathname;

  if (router.pathname.endsWith("/[id]")) {
    active = router.pathname.replace("/[id]", "");
  } else if (router.pathname.endsWith("/new")) {
    active = router.pathname.replace("/new", "");
  }

  const { md } = useDeviceBreakpoints();

  const adminTab = useHasPermission("admin");

  const items = [];

  if (adminTab) {
    items.push(
      {
        key: "/utenti",
        label: <Link href="/utenti">Utenti</Link>,
      },
      {
        key: "/prodotti",
        label: <Link href="/prodotti">Prodotti</Link>,
      },
      {
        key: "/clienti",
        label: <Link href="/clienti">Clienti</Link>,
      },
      {
        key: "/clienti-finali",
        label: <Link href="/clienti-finali">Clienti finali</Link>,
      },
      {
        key: "/invii",
        label: <Link href="/invii">Invii</Link>,
      },
      {
        key: "/logout",
        label: <Link href="/logout">Logout</Link>,
      }
    );
  } else {
    items.push(
      {
        key: "/clienti",
        label: (
          <Link href={`/clienti/${user.user?.customer?.id}`}>Clienti</Link>
        ),
      },
      {
        key: "/logout",
        label: <Link href="/logout">Logout</Link>,
      }
    );
  }

  return (
    <Menu
      mode={md ? "horizontal" : "vertical"}
      className={styles.Menu}
      selectedKeys={[active]}
      defaultActiveFirst={true}
      disabledOverflow
      items={items}
    />
  );
};

export default NavigationMenu;
