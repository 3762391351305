import "@/styles/globals.scss";

import { ApolloProvider } from "@apollo/client";
import AppLayout from "@/containers/app-layout/AppLayout";
import type { AppProps } from "next/app";
import { ConfigProvider } from "antd";
import { NextAdapter } from "next-query-params";
import PrivateRoute from "@/guards/private-route/PrivateRoute";
import PublicRoute from "@/guards/public-route/PublicRoute";
import { QueryParamProvider } from "use-query-params";
import advancedFormats from "dayjs/plugin/advancedFormat";
import client from "../graphql/apollo-client";
import dayjs from "dayjs";
import it from "antd/lib/locale/it_IT";
import { useIsClient } from "usehooks-ts";
import { useRouter } from "next/router";

dayjs.locale("it");
dayjs.extend(advancedFormats);

export default function App({ Component, pageProps }: AppProps) {
  const isClient = useIsClient();
  const { pathname } = useRouter();

  if (!isClient) {
    return null;
  }
  const validateMessages = {
    required: "${label} e' richiesto!",
  };
  return (
    <ApolloProvider client={client}>
      <ConfigProvider
        form={{ validateMessages }}
        locale={it}
        prefixCls="opi"
        iconPrefixCls="opiicon"
        theme={{
          token: {
            colorPrimary: "#1a416c",
            colorSuccess: "#009037",
            fontFamily: "Raleway",
            borderRadius: 0,
            colorPrimaryBg: "#1252a414",
          },
        }}
      >
        {pathname === "/logout" ? (
          <Component {...pageProps} />
        ) : pathname === "/" ||
          pathname === "/password-recovery" ||
          pathname === "/password-reset" ||
          pathname === "/invite" ? (
          <PublicRoute>
            <Component {...pageProps} />
          </PublicRoute>
        ) : (
          <PrivateRoute>
            <AppLayout>
              <QueryParamProvider
                adapter={NextAdapter}
                options={{ removeDefaultsFromUrl: true }}
              >
                <Component {...pageProps} />
              </QueryParamProvider>
            </AppLayout>
          </PrivateRoute>
        )}
      </ConfigProvider>
    </ApolloProvider>
  );
}
