import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";

import { User } from "@/graphql/output/graphql";
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
  useFragment_experimental,
} from "@apollo/client";

export type LoggedUser =
  | (object &
      Pick<
        User,
        | "avatar_text"
        | "name"
        | "id"
        | "email"
        | "last_login_at"
        | "revoked"
        | "failed_logins"
        | "active"
        | "deleted"
        | "roles"
        | "all_permissions"
        | "isAdmin"
        | "isCliente"
        | "customer"
      >)
  | null;

interface UserProviderProps {
  user: LoggedUser;
}

const useUserFragment = (
  fragment: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  fragmentName: string | undefined = undefined
) => {
  const id = useUserContext();

  return useFragment_experimental({
    fragment,
    fragmentName,
    from: {
      __typename: "User",
      id,
    },
  });
};

const UserContext = createContext<{
  user: LoggedUser;
  setUser: (user: LoggedUser) => void;
}>({
  user: null,
  setUser: () => {},
});

const useUserContext = () => {
  const context = useContext(UserContext);

  if (!context)
    throw new Error(
      "No UserContext.Provider found when calling useUserContext."
    );

  return context;
};

const UserProvider: FC<PropsWithChildren<UserProviderProps>> = ({
  user: value,
  children,
}) => {
  const [user, setUser] = useState<LoggedUser>(value);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, useUserContext, useUserFragment };
