import React, { FC } from "react";

import { Typography } from "antd";

const PageFooter: FC = () => {
  return (
    <div>
      <Typography.Text>
        Arvan - Fertilizzanti, scienza del suolo e dell&apos;ambiente
        <br />
        Via Gramsci, 59
        <br />
        30034 Mira (Venezia)
        <br />
        P.Iva 03029480278
      </Typography.Text>
    </div>
  );
};

export default PageFooter;
